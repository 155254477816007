// src/App.js

import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import logo from './logo-no-background.png';

function Home() {
  return (
    <>
      <header className="bg-teal-500 min-h-[50vh] flex flex-col items-center justify-center text-white w-full">
        <h1 className="text-6xl m-0">Latgolo</h1>
        <h2 className="text-3xl my-5">Recruiting made easy</h2>
      </header>
      <p className="text-xl my-4 max-w-lg mx-auto">
        Welcome to Latgolo! We make the recruiting process seamless and efficient. 
        Join us to find the best talent for your organization with ease.
      </p>
      <div className='flex justify-center'> 
      <a
        href="#get-started"
        className="text-xl text-white bg-teal-500 py-2 px-5 rounded-md mt-5 border-2 border-white transition-colors duration-300 hover:bg-white hover:text-teal-500 inline-block"
      >
        Get Started
        </a>
        </div>
    </>
  );
}

function AboutUs() {
  return <h2 className="text-2xl my-5">About Us</h2>;
}

function ContactUs() {
  return <h2 className="text-2xl my-5">Contact Us</h2>;
}

function FindJobs() {
  return <h2 className="text-2xl my-5">Find Jobs</h2>;
}

function HireTalent() {
  return <h2 className="text-2xl my-5">Hire Talent</h2>;
}

function App() {
  return (
    <div className="text-center bg-white text-gray-800 min-h-screen flex flex-col font-sans">
      <nav className="flex justify-between items-center bg-teal-500 p-4 w-full border border-b-black">
       <Link to="/"> <img src={logo} alt="Latgolo Logo" className="h-20" /></Link>
        <div className="flex gap-5 ">
          <Link to="/about-us" className="text-white text-lg no-underline py-1 px-3 border-2 border-transparent transition-colors duration-300 hover:bg-white hover:text-teal-500">About Us</Link>
          <Link to="/contact-us" className="text-white text-lg no-underline py-1 px-3 border-2 border-transparent transition-colors duration-300 hover:bg-white hover:text-teal-500">Contact Us</Link>
          <Link to="/find-jobs" className="text-white text-lg no-underline py-1 px-3 border-2 border-transparent transition-colors duration-300 hover:bg-white hover:text-teal-500">Find Jobs</Link>
          <Link to="/hire-talent" className="text-white text-lg no-underline py-1 px-3 border-2 border-transparent transition-colors duration-300 hover:bg-white hover:text-teal-500">Hire Talent</Link>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/find-jobs" element={<FindJobs />} />
        <Route path="/hire-talent" element={<HireTalent />} />
      </Routes>
    </div>
  );
}

export default App;
